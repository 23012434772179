<template>
  <div>
    <b-card>
        <Table :url="url" :fields="fields">

          <template #cell(rating)="data">
            {{ data.item.invoice.scorings[0].rating }}
          </template>

          <template #cell(total_amount)="data">
            {{ amountFormat(data.item.total_amount, data.item.invoice.currency.name) }}
          </template>

          <template #cell(date_of_purchase)="data">
            {{ data.item.created_at.substring(10,0) }}
          </template>

          <template #cell(due_date)="data">
            {{ data.item.invoice.due_date.substring(10,0) | formatDate }}
          </template>

          <template #cell(roi_percent)="data">
            {{ calculateRoiPercent(data.item.invoice.scorings[0]) }}
          </template>

          <template #cell(purchase_date)="data">
            {{ data.item.created_at | formatDate }}
          </template>

          <template #cell(roi)="data">
            {{ data.item.total_net_return }}
          </template>

          <template #cell(factoring_type)="data">
            <template v-if="data.item.invoice.recourse == 1">
              <span v-if="data.item.is_reverse == 1">
                Reverse
              </span>
              <span v-else>
                Recourse
              </span>
            </template>
            <template v-else>
              <span>
                Non-Recourse
              </span>
            </template>
          </template>

          <template #cell(progress)="data">
            <b-progress
              variant="success"
              :value="data.item.total_percentage"
              max="100"
              width="100px"
              height="14px"
            />
            <span> {{ data.item.total_percentage }}% </span>
          </template>
        </Table>
    </b-card>
  </div>
</template>

<script>
import Table from "@/components/common/Table2.vue";
import { CurrencyAmountFormat } from '@core/utils/CurrencyAmountFormat';


export default {
  name: "MyInvoices",

  components: {
    Table,
  },
  data() {
    return {
      fields: [
        {
          key: "invoice_id",
          label: "Id",
        },
        {
          key: "rating",
          label: "rating",
        },
        {
          key: "total_amount",
          label: "amount",
        },
        {
          key: "roi",
          label: "roi",
        },
        {
          key: "roi_percent",
          label: "roi %",
        },
        {
          key: "purchase_date",
          label: "purchase_date",
        },
        {
          key: "due_date",
          label: "due_date",
        },
        {
          key: "factoring_type",
          label: "factoring_type",
        },
        {
          key: "status",
          label: "status",
        },
        {
          key: "progress",
          label: "funding_status",
        },
      ],
      roi_percent: null,
      title: "my_invoices",
      url: "/invoice/my_invoices",
    };
  },
  methods: {

    calculateRoiPercent(scoring) {
      let roi_percent = scoring.discount_in_percentage - scoring.fee_hive
      return roi_percent.toFixed(2)
    },

    amountFormat(amount, currency) {
      return CurrencyAmountFormat(amount, currency)
    },
  },
};
</script>


<style scoped>

</style>